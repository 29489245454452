<template>
	<div class="d-flex align-items-center bg-auth border-top border-top-2 border-primary">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
					<!-- Image -->
					<div class="text-center">
						<img src="@/assets/boolink/sally2.png" alt="..." class="img-fluid">
					</div>

				</div>
				<div class="col-12 col-md-5 col-xl-4 order-md-1 my-5">

					<!-- Heading -->
					<img src="@/assets/source/logo-white.svg" alt="..." class="img-fluid mb-2"/>

					<!-- Subheading -->
					<p class="color-white mb-5 mt-2">
						Enter your email to get a password reset link.
					</p>

					<b-alert variant="danger" :show="!!resErr.message">
						<div v-html="resErr.message"></div>
					</b-alert>

					<b-alert variant="success" :show="!!res._id">
						Request sent to your email
					</b-alert>

					<!-- Form -->
					<form>
						<!-- Email address -->
						<div class="form-group">
							<label class="color-white">Email Address</label>
							<input type="email" class="form-control" placeholder="name@address.com" v-model="email">
						</div>

						<!-- Submit -->
						<button-loading
							type="submit"
							:loading="loading"
							@click="resetPasswordFunc()"
							class="btn btn-lg btn-block btn-primary mb-3 custom-btn"
						>{{ $t("Reset Password") }}
						</button-loading>

						<!-- Link -->
						<div class="text-center">
							<small class="color-white">
								Remember your password?
								<router-link
									style="display: inherit;"
									class="form-text color-white"
									:to="{ name: 'login'}"
								>{{ $t('Log in') }}
								</router-link>
							</small>
						</div>

					</form>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";
import ButtonLoading from "@/components/ButtonLoading";

export default {
	components: {
		ButtonLoading
	},
	data() {
		return {
			email: '',
			res: {},
			resErr: {}
		};
	},
	computed: {
		...mapState("authManagement", {
			loading: "isCreatePending"
		}),
	},
	created() {
	},
	methods: {
		...mapActions("authManagement", {
			resetPassword: "create",
		}),

		resetPasswordFunc() {

			this.resetPassword({
				action: "sendResetPwd",
				value: {
					email: this.email
				}
			})
				.then((res) => {
					this.res = res;
					//this.$router.push({name: 'login'});
				})
				.catch((error) => {
					console.log(error)
					this.resErr = error
				});
		},
	},
};
</script>
